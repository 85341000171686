.remove-card-wrapper {
  width: 220px;
  // padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(246, 101, 90, 0.81);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);

  .form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-wrapper {
      width: 100%;
    }
    h1.delete-products-heading {
      font-weight: 600;
      font-size: 16px;
      color: white;
      margin: 0;
    }
    p {
      font-size: 12px;
      font-weight: 400 !important;
      line-height: 16px;
      color: white;
      margin-bottom: 0px;
    }
  }
  .back-form {
    display: flex;
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
  .remove-btn-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    color: white;
    button {
      color: white;
    }
    .white {
      width: 105px;
      height: 38px;
      border-radius: 5px;
      background-color: white;
      color: black;
    }
    .transparent {
      width: 105px;
      height: 38px;
      border-radius: 5px;
      color: #fff;
    }
  }
}
