.video-uploader {
  position: relative;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  height: 315px;
  min-height: 315px;
  max-height: 315px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px #00000026;
  overflow: hidden;
  margin-right: 20px;

  .video-upload-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1;
    & .video-upload-content {
      display: flex;
      justify-content: center;
      align-items: center;
      & .loader-animation {
        margin-top: 1px;
      }
      & .loader-animation svg {
        width: 14px;
        height: 14px;
      }
    }
    .video-upload-progressbar {
      margin-top: 8px;
      width: 130px;
      height: 4px;
      background: rgba(255, 255, 255, 0.5);
      position: relative;
      overflow: hidden;
      border-radius: 100px;
      .video-upload-progress {
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
        height: 4px;
      }
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .close-btn {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 5px;
    right: 5px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    img {
      height: 10px;
      width: 10px;
    }
  }
  .video-uploader-title-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: white;
    bottom: 0;
    height: 40px;
    width: 100%;
    z-index: 2;
    img {
      width: 12px;
      margin-left: 4px;
      cursor: pointer;
      padding: 11px;
    }

    input {
      width: 70%;
      outline: none;
      font-size: 12px;
      box-shadow: none;
      background-color: transparent;
      color: #000;
      height: auto;
      border: unset;
      padding: 1px;
    }
  }
}
