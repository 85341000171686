button {
  margin: 0;
  padding: 0;
}

.cta-not-selected {
  opacity: 0.2;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1);
  }
}

.cta-selected {
  opacity: 1;
  height: 36px;
  width: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1);
  }
}
