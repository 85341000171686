.stats-card-graph-wrapper{
  // position: absolute;
  // bottom: 0px;
  // top: 32px;
  overflow: auto;
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px !important;
    display: block !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 10px;
    background-color: #adadad;
  }
  &::-webkit-scrollbar-track {
    background: #dcdcdc !important;
    width: 4px !important;
  }
}

svg:not(:root) {
  overflow: auto !important;
}

.recharts-responsive-container {
  // height: fit-content !important;
  max-height: 2000px !important;
}