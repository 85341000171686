.main-dash-container {
  justify-content: center;
  overflow-y: scroll;
  padding-left: 180px;
  padding-right: 50px;
  padding-top: 68px;
  .content-width {
    width: 100%;
  }
}

@media only screen and (min-width: 1500px) and (max-device-width: 1600px) {
  .main-dash-container {
    .content-width {
      width: 94%;
    }
  }
}

@media only screen and (min-width: 1601px) and (max-device-width: 1800px) {
  .main-dash-container {
    .content-width {
      width: 88%;
    }
  }
}

@media only screen and (min-width: 1800px) and (max-device-width: 1900px) {
  .main-dash-container {
    margin-left: 50px;
    .content-width {
      width: 76%;
    }
  }
}

@media only screen and (min-width: 1900px) and (max-device-width: 2100px) {
  .main-dash-container {
    margin-left: 50px;
    .content-width {
      width: 70%;
    }
  }
}
@media only screen and (min-width: 2100px) and (max-device-width: 2300px) {
  .main-dash-container {
    margin-left: 50px;
    .content-width {
      width: 64%;
    }
  }
}

@media only screen and (min-width: 2300px) and (max-device-width: 2500px) {
  .main-dash-container {
    margin-left: 50px;
    .content-width {
      width: 57%;
    }
  }
}

@media only screen and (min-width: 2500px) and (max-device-width: 2700px) {
  .main-dash-container {
    margin-left: 50px;
    .content-width {
      width: 52%;
    }
  }
}

.space-count {
  height: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 700;
  border-radius: 3px;
  display: inline-block;
  padding: 3px 6px 0px 6px;
  cursor: default;
}

.active-list {
  min-height: 560px;
  position: relative;
}

.loading-space {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.products-heading {
  h1 {
    margin-top: 9px;
    overflow: hidden;
    font-size: 18px;
  }
  .button-link {
    background-color: black;
    padding: 15px 20px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    img {
      height: 13px;
      margin-right: 10px;
    }
    &:hover {
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    }
  }
}

.products-search-wrapper {
  button {
    transition: all 0.2s ease-in-out;
    border-radius: 3px;

    span {
      width: auto;
    }
  }
}

.product-section {
  height: calc(100vh - 250px);
}

.active-products-wrapper {
  overflow: visible;
}

// Pagination

// pagination Wrapper
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: inherit;
}

// Paginate Container

.paginate-container {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  margin-bottom: 45px !important;
}
.page-item {
  height: 30px;
  width: 30px;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  transition: all 0.15s ease-in;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.27);
  border-radius: 5px;
}

.page-item:hover {
  color: rgba(0, 0, 0, 0.67);
}

.page-item-active {
  background-color: black;
  color: white;
}

.page-item-active:hover {
  background-color: black;
  color: white;
}

.page-item-link {
  border: 0px;
  cursor: pointer;
  outline: none;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  // &:hover {
  //   background-color: black;
  //   color: white;
  //   transform: scale(1.1);
  // }
  // &:active {
  //   transform: scale(0.9);
  // }
  border-radius: 5px;
}

.next-previous-class {
  text-align: center;
  margin: 0px 15px;
  width: 30px;
  border-radius: 5px;
  transition: all 0.15s ease-in-out;
  border: 0px;
  cursor: pointer;
  outline: none;
}

.next-previous-link-class {
  outline: none;
  width: 30px;
  height: 30px;
  color: rgba(0, 0, 0, 0.27);
}

.disabled-class {
  cursor: not-allowed;
}

.prop-card-info-wrapper {
  display: none !important;
  &:first-child {
    display: block !important;
  }
  font-size: 8px;
  font-weight: 700;
  opacity: 0.2;
  .heading-info {
    width: 25%;
    margin-left: 40px;
  }
  .pairing-info {
    width: 10%;
  }
  .hashtags-info {
    width: 15%;
  }
  .videoswrapper-info {
    width: 15%;
  }
}

.empty-state-height {
  height: calc(100vh - 250px);
  margin-top: 20px;
  box-shadow: 0px 100px 200px rgba(0, 0, 0, 0.15);
  padding-top: 10px;
}

@media screen and (max-width: '1020px') {
  .empty-state-height {
    height: calc(100vh - 300px);
  }
}

.empty-state-add-new {
  background-color: black;
  padding: 15px 30px;
  color: white;
  font-size: 12px;
  border-radius: 6px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
  }
}
.custom-bottom {
  bottom: 35px;
}

@media screen and (min-width: 1900px) {
  .empty-state-height {
    padding-top: 60px;
  }
  .custom-bottom {
    bottom: 70px;
  }
}

.search-hr {
  height: 0.71px;
  width: 106%;
  background-color: #000;
}
.paginate-container > li.break > a {
  color: rgba($color: #000000, $alpha: 0.27);
}

.first-product-container {
  &-item {
    height: 220px;
    width: 220px;
    border-radius: 15px;
    border: 1px dashed #898989;
    left: 50%;
    transform: translate(-50%, 10px);
    position: relative;
    &-plus {
      width: 60px;
      height: 4px;
      background-color: rgba(57, 57, 57, 0.16);
      &::after {
        content: '';
        height: 60px;
        width: 4px;
        background-color: rgba(57, 57, 57, 0.16);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        content: '';
        height: 4px;
        width: 4px;
        background-color: rgb(241, 241, 241);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  h4 {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  span {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
}

.button-edit-link {
  background-color: transparent;
  padding: 9px 10px;
  color: rgba(0, 0, 0, 0.56);
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.56);
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 16px;
    margin-right: 5px;
  }
}

.button-delete-link {
  background-color: transparent;
  padding: 9px 10px;
  color: rgba(255, 0, 0, 0.56);
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(255, 0, 0, 0.56);
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 16px;
    margin-right: 5px;
  }
}

.button-done-link {
  background-color: #fff;
  padding: 9px 10px;
  color: rgba(0, 0, 0, 0.56);
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #fff;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  img {
    height: 8px;
    margin-right: 5px;
  }
}

.products-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}
