.homepage {
  width: 100vw;
  height: 100vh;
  position: relative;
  margin: 0;
  padding: 0;
  // position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  & .sign-in{
    display: flex;
    column-gap: 8px;
    position: absolute;
    top: calc(3px + 5%);
    right: 5%;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.6);
    // color: #000000;
    z-index: 100000;
    & .btn-wrapper {
      width: 40px;
      height: 12px;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.88);
      // color: #000;
      & .btn {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
}

.landing-container {
  width: 900px;
  height: 796px;
  max-height: 796px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(70px);
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  padding-top: 85px;
  overflow: hidden;
  & .plan-details {
    width: 100%;
    padding: 0 64px;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    & .current-plan {
      width: 425px;
      padding-right: 100px;
      box-sizing: border-box;
      & h1 {
        font-size: 24px;
        line-height: 28px;
        color: #000;
        font-weight: 700;
        margin: 0px;
      }
      & .tagline {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        color: rgba(0,0,0,0.5);
        margin-top: 16px;
      }
      & .btn-wrapper {
        width: 236px;
        height: 48px;
        margin-top: 28px;
        background: #000;
        box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
        border-radius: 6px;
        & .btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          cursor: pointer;
        }
      }
      & .payment-info {
        margin-top: 9px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #4425f5;
      }
    }

    & .tier-sep {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 48%;
      transform: translate(-150%, -50%);
    }
    & .plan-comparator {
      & h3 {
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        color: #000000;
      }
      & h2 {
        margin: 0;
        margin-top: 2px;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 30px;
      }

      & .slider-tier {
        margin-top: 40px;
      }

      & .current-tier {
        margin-top: 40px;
        height: 62px;
        & .price {
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          color: #000000;
          & span {
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }
        & .visitors {
          margin-top: 2px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          & span {
            color: #4425f5;
          }
        }
      }
    }
  }
  & .feature-carousel {
    margin-top: 79px;
    width: 900px;
    height: 440px;
    // overflow: hidden;
    position: relative;
    & .feature {
      width: 900px !important;
      height: 440px;
      box-sizing: border-box;
      background: #000000;
      position: relative;
      transform: translateX(-140px);
      transition: all 0.3s ease;
      & .left-line {
        width: 8px;
        height: 1px;
        background: #404040;
        position: absolute;
        right: 0px;
        bottom: 32px;
      }
      & .right-line {
        width: 8px;
        height: 1px;
        background: #404040;
        position: absolute;
        left: 0px;
        bottom: 32px;
      }
      &.two{
        background: #161616 !important;
      }
      &.four{
        background: #161616 !important;
      }
      & .feature-background {
        position: absolute;
        left: 216px;
        bottom: 28px;
      }
      & .img-wrap {
        width: 368px;
        height: 212px;
        position: absolute;
        top: 114px;
        left: 104px;
        & img {
          width: 100%;
          height: 100%;
        }
      }
      & .feature__details {
        position: absolute;
        right: 120px;
        top: 52px;
        text-align: left;
        color: #FFFFFF;
        width: 232px;
        & h1 {
          margin: 0;
          font-size: 8px;
          line-height: 12px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          font-weight: 700;
          position: relative;
          display: flex;
          align-items: center;
          & .hr-line {
            display: block;
            margin-left: 20px;
            width: 44px;
            height: 1px;
            background: rgba(255, 255, 255, 0.2);
          }
        }
        & .feature__title {
          margin-top: 110px;
          font-size: 18px;
          line-height: 110%;
          font-weight: 700;
        }
        & .feature__subtitle {
          font-weight: 600;
          margin-top: 16px;
          font-size: 12px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
      & .feature__no {
        font-size: 18px;
        line-height: 16px;
        color: #FFFFFF;
        font-weight: 700;
        opacity: 0.16;
        position: absolute;
        right: 16px;
        bottom: 24px;
        &.left {
          left: 16px;
          right: unset;
          // border: 1px solid #FFFFFF;
          font-size: 18px;
          line-height: 16px;
          letter-spacing: 0.08em;
          opacity: 0.16;
        }
      }
      & .next-arrow {
        width: 36px;
        height: 86px;
        position: absolute;
        top: 177px;
        right: -18px;
        cursor: pointer;
      }
    }
  }
}

.slick-slide.slick-active.slick-current {
  width: 900px !important;
}