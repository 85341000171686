.product-logo {
  @include media('<phone') {
    top: 5%;
    left: 5%;
  }

  @include media('>=phone', '<lgphone') {
    top: 6%;
    left: 6%;
  }
  @include media('>=lgphone', '<tablet') {
    top: 10%;
    left: 5%;
  }
}
