//   Member Item
.member-item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 122px;
  max-width: 251px;
  min-width: 251px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 7.90989px 15.8198px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
  }
  .member-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      height: 58px;
      width: 58px;
      border-radius: 100%;
      background-color: black;
      outline: none;
    }
    .content {
      margin-left: 14px;
      width: 132px;
      overflow: hidden;

      h1 {
        font-size: 13px;
        font-weight: 700;
        margin: 0 !important;
        overflow: hidden;
        height: 15px;
      }
      span {
        font-size: 8px;
        font-weight: 700;
        line-height: 9.6px;
        padding: 4px;
        border-radius: 2.27px;
        color: white;
      }
      .products-info {
        margin-top: 10px;
        img {
          height: 11px;
          width: 8px;
          background: transparent;
          border-radius: 0;
          margin-right: 2px;
        }
        font-size: 8px;
        font-weight: 700;
      }
    }
  }
  .setting-btn {
    position: absolute;
    z-index: 100;
    top: 7px;
    right: 10px;
    img {
      width: 29px;
      height: 29px;
    }
  }
  .setting-menu {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    z-index: 9;
    border-radius: 18px;
    padding: 15px 0px;
    height: auto;
    width: 214px;
    box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.15);
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      font-size: 11px;
      font-weight: 700;
      margin-left: 20px;
      img {
        width: 19px;
        height: 19px;
      }
    }
  }
}

.member-item-wrapper:not(last-child) {
  margin-bottom: 20px;
}

.member-item-fade {
  opacity: 0.5 !important;
}
