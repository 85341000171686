.topbar {
  width: 100%;
  height: 67px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.15);
  z-index: 99;
  top: 0;
  backdrop-filter: blur(17.5px);
  -webkit-backdrop-filter: blur(17.5px);
  .product-logo-topbar {
    height: 20px;
  }
}

.left-menu-wrap {
  margin-right: 70px;
  cursor: pointer;

  .dataused-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    height: 42px;
    width: 166px;
    border-radius: 6px;
    border: 1px solid rgba(228, 228, 228, 1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid rgba($color: #000000, $alpha: 0.5);
    }

    h1 {
      font-size: 9px;
      font-weight: 700;
      line-height: 11px;
      margin-left: 12px;
      color: #3a38f5;
      text-transform: uppercase;
    }
  }

  .dataused-wrapper-business {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    height: 42px;
    width: 166px;
    border-radius: 6px;
    border: 1px solid #dadada;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid rgba($color: #000000, $alpha: 0.5);
    }
    .col {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      h2 {
        font-size: 6.3px;
        font-weight: 700;
        line-height: 7.6px;
        margin: 0;
        padding: 0;
        text-align: left;
        color: #3a38f5;
      }
      h1 {
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        margin: 0;
        text-align: left;
        margin-top: 3px;
      }
    }
    .right-border {
      border-right: 1px solid #cbcbcb;
    }
  }

  .top-menu-item {
    color: black;
    font-size: 12px;
    font-weight: 700;
    margin: 20px;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
  .top-menu-item-user {
    overflow: hidden;
    background-color: black;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    height: 41px;
    width: 41px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      // transform: scale(1.1);
    }
    &:active {
      // transform: scale(0.9);
    }
  }
}

.payment-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  &.success {
    padding: 16px 24px;
    background: rgba(0, 0, 0, 0.92);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  &.failed {
    top: 50px;
    background: #f6655a;
    box-shadow: 0px 10px 30px rgba(255, 0, 0, 0.25);
    border-radius: 8px;
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .msg {
      & .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
      & .title-msg {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        margin-top: 4px;
        max-width: 375px;
      }
    }
    & .try-again {
      background: rgba(255, 255, 255, 0.96);
      border-radius: 6px;
      box-sizing: border-box;
      width: 110px;
      height: 44px;
      padding: 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #f6655a;
      cursor: pointer;
    }
  }
}
