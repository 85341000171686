.sign-in{
  display: flex;
  column-gap: 8px;
  position: absolute;
  top: calc(3px + 5%);
  right: 5%;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.6);
  // color: #000000;
  z-index: 100000;
  & .btn-wrapper {
    width: 40px;
    height: 12px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.88);
    // color: #000;
    & .btn {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
    }
  }
}