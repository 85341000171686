.passinput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  img {
    position: absolute;
    cursor: pointer;
    transform: translate(13rem, -100%);
  }
}
