.markeing-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .marketing-form {
    background: rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 12px;

    -webkit-box-shadow: 0px 0px 47px -30px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 0px 47px -30px rgba(0, 0, 0, 0.48);
    box-shadow: 0px 0px 47px -30px rgba(0, 0, 0, 0.48);
  }
}

.select-input {
  width: 100%;
  select {
    height: 40px;
    width: 100%;
    margin: 5px 0px;
    padding: 10px;
    border-radius: 6px;
    min-width: auto;
    border: none;
    font-size: 12px !important;
    font-weight: 900;
    color: black;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    outline: none;
    option {
      height: 40px;
      border: 0px;
      margin: 5px 0px;
      padding: 10px;
    }
  }
}
