.stat-overview {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .icon-wrapper {
    width: 56px;
    height: 48px;
    overflow: hidden;
    &.round {
      border-radius: 50%;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      background-color: #000;
    }
    & img {
      object-fit: cover;
    }
  }

  & .title {
    margin-top: 32px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-align: center;
    color: #000000;
  }

  & .msg {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
}
