.video-container-item {
  position: relative;
  background-color: transparent;
  & .bg-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    object-fit: cover;
  }
  & .video-container-item-desc {
    & input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}