.stats-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-width: 1200px;
  .stats-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 40px;
    padding-bottom: 34px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
  }

  .stats-wrapper {
    margin-top: 30px;
    h2 {
      font-size: 16px;
    }
    .stats-wrapper-cards {
      margin-top: 20px;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-between;
    }
  }
  .stats-product-performance-wrapper {
    margin-top: 50px;
    & .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      h1 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
      }
      h2 {
        margin: 0;
        // margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.2);
        font-weight: 600;
        width: 200px;
        text-align: left;
        margin-right: 10px;
      }
    }

    .stats-product-performance-grid-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr 50px;
      align-items: center;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      padding-top: 10px;
      .stats-product-performance-input-wrapper {
        margin: 0;
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
        }
        input {
          margin: 0;
          border: none;
          background-color: transparent;
          box-shadow: none;
          font-size: 12px;
          font-weight: 600;
          border-radius: 0;
          border: none;
          color: unset;
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          width: 70%;
          transition: all 0.2s ease-in-out;
          &:hover {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
      h2 {
        font-size: 12px;
        margin: 0;
        font-weight: 700;
        opacity: 0.5;
      }
      .stats-product-performance-sorting-btn {
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        opacity: 0.5;
        display: flex;
        align-items: center;
        img {
          height: 10px;
          margin-left: 5px;
        }
      }
    }

    .stats-product-performance-products-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
      height: 360px;

      &::-webkit-scrollbar {
        width: 2px !important;
        display: block !important;
      }
      &::-webkit-scrollbar-thumb {
        width: 2px !important;
        border-radius: 10px;
        background-color: #adadad;
      }
      &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 2px !important;
      }
    }
    .stats-product-performance-product-card {
      width: 100%;
      height: 120px;
      min-height: 120px;
      display: grid;
      align-items: center;
      align-content: center;
      grid-template-columns: minmax(0, 3fr) 1fr 1fr 1fr 1fr 50px;
      background: rgba(255, 255, 255, 0.32);
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:nth-child(even) {
        background: rgba(255, 255, 255, 0.02);
      }

      &:nth-child(odd):hover {
        background-color: rgba(255, 255, 255, 0.7);
      }
      &:nth-child(even):hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
      .stats-product-performance-product {
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
          width: 52px;
          height: 80px;
          border-radius: 8px;
          object-fit: cover;
        }
        .stats-product-performance-product-title {
          margin-left: 10px;
          width: 80%;
          h2 {
            margin: 0;
            font-weight: 700;
            font-size: 10px;
            opacity: 0.5;
          }
          h1 {
            margin: 0;
            font-size: 12px;
            padding-bottom: 0px;
            border-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .total-videos {
        font-weight: 700;
        font-size: 12px;
        padding-left: 1px;
      }
      .product-values {
        font-size: 18px;
        font-weight: 700;
        padding-left: 5px;
      }
      .arr-btn {
        margin-right: 50px;
        width: 15px;
        img {
          width: 15px;
        }
      }
    }

    .stats-product-performance-product-card:last-child {
      opacity: 0;
    }
  }
}

// Loading Card Wrapper
.loading-card-wrapper-graph {
  width: 725px;
  z-index: 100;
  margin-left: 10px;
  margin-top: 5px;
  .loading-top-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    .loading-circle {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;
    }
    .loading-top-rectangle {
      width: 120px;
      height: 16px;
      border-radius: 4px;
    }
  }
  .loading-bottom-rectangle {
    margin-top: 20px;
    height: 152px;
    width: 720px;
    border-radius: 4px;
  }
}

.loading-card-wrapper {
  width: 300px;
  z-index: 100;

  .loading-top-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    .loading-circle {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;
    }
    .loading-top-rectangle {
      width: 120px;
      height: 16px;
      border-radius: 4px;
    }
  }

  .loading-bottom-rectangle {
    margin-top: 80px;
    height: 26px;
    width: 112px;
    border-radius: 4px;
  }
  .loading-bottom-heatmap-rectangle {
    margin-top: 15px;
    height: 152px;
    width: 270px;
    margin-left: 15px;
    border-radius: 4px;
  }
}

.product-empty-container {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  padding-top: 20px;
  background: rgba(255, 255, 255, 0.32);
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  grid-template-columns: minmax(0, 3fr) 1fr 1fr 1fr 1fr 50px;
  .product-empty-main {
    display: flex;
    align-items: center;
  }
  .product-empty-card {
    margin-left: 10px;
    width: 52px;
    height: 80px;
    border-radius: 8px;
  }
  .product-empty-product-details-wrapper {
    margin-left: 10px;
    .product-empty-productname {
      width: 100px;
      height: 12px;
      border-radius: 6px;
    }
    .product-empty-producttagline {
      margin-top: 10px;
      width: 360px;
      max-width: 380px;
      height: 20px;
      border-radius: 6px;
    }
  }
  .product-empty-productval {
    width: 48px;
    height: 20px;
  }
}

.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
