.apple-auth-btn {
  height: 50px;
  background: black;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  padding: 0px 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  color: white;
  img {
    width: 18px;
  }

  .apple-auth-text {
    font-weight: 800;
    font-size: 12px;
    padding: 10px 10px 10px 0px;
    margin-right: 10px;
  }
}
