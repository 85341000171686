.progress-bar-wrapper {
  position: relative;
  height: 8px;
  background-color: #dfdfdf;
  width: 136px;
  border-radius: 41px;
  margin-left: 10px;
  overflow: hidden;

  .current-progress {
    background-color: black;
    height: 8px;
    position: absolute;
    border-radius: 41px;
  }
}
