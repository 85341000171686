
    .block-container {
        margin-top: 65px;
        &-logo {
            width: 150px;
            img {
                height: auto;
                width: 100%;
                display: block;
            }
        }
        &-main {
            position: relative;
            background-color: #fff;
            padding: 30px 0 30px 30px;
            h2 {
                font-size: 28px;
                padding-right: 40px;
                font-weight: 600;
                margin: 0;
            }
            button.btn-link {
                background-color: black;
                width: 100%;
                height: 50px;
                color: white;
                font-size: 16px;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;
                font-weight: 600;
                text-transform: uppercase;
                box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                margin-top: 15px;
                img {
                  height: 13px;
                  margin-right: 10px;
                }
                &:hover {
                  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
                }
              }
        }
        &-min {
            width: 250px;
            float: right;
            margin-top: 10px;
            margin-bottom: 30px;
            img {
                height: auto;
                width: 100%;
                display: block;
                object-position: 24px;
            }
        }
        h4 {
            clear: both;
            font-size: 16px;
            font-weight: 600;
            color: #606060;
            text-align: center;
            height: 35px;
        }
        span {
            display: block;
            font-size: 15px;
            color: #000;
            font-weight: 600;
            text-align: center;
            height: 19px;
        }
        button.secondary-btn-link {
            font-size: 16px;
            font-weight: 600;
            width: 100%;
            margin: 0 auto;
            margin: 30px 0 25px 0;
        }
    }

@media only screen and (max-width: 600px) { 
    .block-container {
        margin-left: 25px;
        margin-right: 25px;
        &-logo {
            margin-left: -12px;
        }
        &-main {
            padding: 30px 0 30px 30px;
            &-padd {
                padding-right: 30px;
            }
            button.btn-link {
                width: 100%;
                height: 50px;
                margin: 0 auto;
                margin-top: 15px;
                &:hover {
                  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
                }
              }
        }
        &-min {
            width: 250px;
            float: right;
            margin-top: 10px;
            margin-bottom: 30px;
        }
        button.secondary-btn-link {
            margin: 30px 0 25px 0;
        }
    }
}

@media only screen 
  and (min-width: 600px) 
  and (max-width: 768px){
    .block-container {
        margin-top: 55px;
        margin-left: 70px;
        margin-right: 70px;
        &-logo {
            margin-left: -34px;
            margin-top: 60px;
        }
        &-main {
            h2 {
                padding-right: 180px;
            }
            &-padd {
                padding: 0 110px 0 60px;
            }
            margin-top: 25px;
            padding: 50px 0 60px 50px;
            h4 {
                height: 50px;
            }
            button.btn-link {
                margin-top: 30px;
              }
        }
        &-min {
            width: 420px;
            float: right;
            margin-top: 50px;
            margin-bottom: 55px;
        }
        button.secondary-btn-link {
            margin: 50px 0 30px 0;
        }
    }
  }

@media only screen 
  and (min-width: 768px) 
  and (max-width: 800px) {
    .block-container {
        margin-top: 55px;
        margin-left: 70px;
        margin-right: 70px;
        &-logo {
            margin-left: -34px;
            margin-top: 100px;
        }
        &-main {
            h2 {
                padding-right: 180px;
            }
            &-padd {
                padding: 0 180px 0 110px;
            }
            margin-top: 25px;
            padding: 70px 0 80px 70px;
            button.btn-link {
                width: 100%;
                height: 50px;
                margin: 0 auto;
                margin-top: 30px;
                &:hover {
                  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
                }
              }
            h4 {
                height: 50px;
            }
        }
        &-min {
            width: 450px;
            float: right;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        button.secondary-btn-link {
            margin: 50px 0 30px 0;
        }
    }
}

.outer-mobile-block {
    height: 100%;
    width: 100%;
    background-color: #E2E2E2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

@media only screen and (min-width: 800px) {
    .outer-mobile-block {
        display: none;
    }
}
