.remove-card-wrapper {
  width: 230px;
  padding: 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(246, 101, 90, 0.81);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);

  .form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-wrapper {
      width: 100%;
    }
  }
  .image-wrapper {
    overflow: hidden;
    height: 142px;
    width: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0px 6.63884px 49.7913px rgba(0, 0, 0, 0.25);
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .back-form {
    display: flex;
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: white;
  }
  p {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: white;
  }
  .remove-btn-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    color: white;
    button {
      color: white;
    }
    .white {
      width: 105px;
      height: 38px;
      border-radius: 5px;
      background-color: white;
      color: #f6655a;
    }
  }
  // Checkbox
  .checkbox-tick {
    position: absolute;
    height: 12px;
    width: 12px;
    left: 6px;
  }

  .checkbox-label {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'] {
      --active: rgba(255, 255, 255, 0.432);
      --active-inner: rgba(255, 255, 255, 0);
      --focus: 0.1px rgb(255, 255, 255);
      --border: rgb(255, 255, 255);
      --border-hover: rgb(255, 255, 255);
      --background: rgba(255, 255, 255, 0.432);
      --disabled: #f6f8ff63;
      --disabled-inner: #e1e6f9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      width: 21px;
      outline: none;
      display: inline-block;
      position: relative;
      margin: 0;
      margin-left: 2px;
      cursor: pointer;
      border: 2px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
          opacity var(--d-o, 0.2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
      }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }

      & + label {
        font-size: 12px;
        font-weight: 700;
        color: white;
        line-height: 14.2px;
        display: inline-block;
        margin-left: 12px;
      }
    }
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 3px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 6px;
          top: 3px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
    }
  }
}
