.label-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  .fake-checkbox {
    display: inline-block;
    width: 23px;
    height: 23px;
    border: 2px solid #000;
    background-color: white;
    vertical-align: middle;
    border-radius: 4px;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 10px;
      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      transition: all 0.2s;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    &:checked + .fake-checkbox {
      border-color: #333;
      &:before {
        transform: translate(-50%, -60%) rotate(45deg) scale(1);
      }
    }
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    font-weight: 700;
    margin-left: 10px;
    a {
      color: #0047ff;
      cursor: help;
    }
  }
}
