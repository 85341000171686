.prod-container {
  cursor: pointer;
  position: relative;
  height: 330px;
  width: 185px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  margin-bottom: 35px;
  &-header {
    justify-content: space-between;
    min-height: 26px;
    &-vid {
      font-size: 9px;
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
      font-weight: bold;
      span {
        margin-left: 4px;
        display: block;
      }
    }
  }
  &-videos {
    position: relative;
    transform: scale(0.88);
    .cards-box-shadow {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
    }
    &-img {
      width: 125px;
      border-radius: 15px;
      filter: drop-shadow(0px 7.18635px 14.3727px rgba(0, 0, 0, 0.2));
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform-origin: bottom left;
      }
    }
    &-img:nth-child(1) {
      height: 200px;
      z-index: 4;
    }
    &-img:nth-child(2) {
      height: 180px;
      z-index: 3;
      transform: translate(-50%, -53%);
    }
    &-img:nth-child(3) {
      height: 160px;
      z-index: 2;
      transform: translate(-50%, -56%);
    }
    &-img:nth-child(4) {
      height: 140px;
      z-index: 1;
      transform: translate(-50%, -59%);
    }
  }
  &-desc {
    align-self: flex-start;
    margin-left: 8px;
    min-height: 62px;
    h4 {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      margin: 0;
      min-height: 20px;
      span {
        font-size: 14px;
        margin-top: -2px;
        display: inline-block;
        font-weight: 600;
      }
    }
    h6 {
      margin: 5px 0 15px 0;
      font-weight: 600;
      font-size: 10px;
      color: #000;
      display: flex;
      align-items: flex-end;
    }
  }
}

.videos-img-rot1 {
  border-radius: 15px;
  transform: rotate(-8deg);
}
.videos-img-rot2 {
  border-radius: 10px;
  transform: rotate(-4deg);
}
.videos-img-rot3 {
  border-radius: 8px;
}
.videos-img-rot4 {
  border-radius: 7px;
  transform: rotate(+4deg);
}

.videos-img-rot-single {
  border-radius: 15px;
}

.videos-img-rot-double-1 {
  border-radius: 15px;
  transform: rotate(-4deg);
}
.videos-img-rot-double-2 {
  border-radius: 15px;
  transform: rotate(3deg);
}

.videos-img-rot-third-1 {
  border-radius: 15px;
  transform: rotate(-7deg);
}
.videos-img-rot-third-2 {
  border-radius: 10px;
}
.videos-img-rot-third-3 {
  border-radius: 8px;
  transform: rotate(7deg);
}

.products-custom-checkbox {
  z-index: 2;
  position: relative;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #c0c0c0;
  background-color: #fff;
  cursor: pointer;
  padding: 2px;
  div {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    background-color: #001aff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
