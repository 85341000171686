@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d_stats-container {
  // width: 1204px;
  margin-left: 25px;
  padding-bottom: 55px;

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
    // position: sticky;
    // top: 160px;
    & .d_stats-hide {
      width: 32px;
      height: 32px;
      background: #ffffff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      @include center-content;
      cursor: pointer;
      & img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }

    & .product-img {
      margin-left: 21px;
      width: 42px;
      min-width: 42px;
      height: 56px;
      min-height: 56px;
      background: #ffffff;
      box-shadow: 0px 7.18635px 14.3727px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      & img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }

    & .product-name {
      margin-left: 12px;

      & .title {
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #bfbfbf;
      }

      & .value {
        margin-top: 6px;
        // text-transform: capitalize;
        color: #000;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
      }
    }
  }

  .select-date-wrapper {
    width: 212px;
    height: 44px;
    border-radius: 6px;
    background: #000000;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    padding: 0 16px;
    & .select-date {
      @include center-content;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      cursor: pointer;
      & .dates {
        width: 158px;
        @include center-content;
        justify-content: space-between;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        margin-left: 10px;
        color: #ffffff;
      }
    }
  }

  .hr-break {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    margin: 16px 0 12px 0;
  }
  .last-updated {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    text-align: right;
    color: rgba(0, 0, 0, 0.2);
  }

  &__grid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: auto;
    row-gap: 40px;
    height: 760px;
    box-sizing: border-box;
    column-gap: 60px;
  }
}

.card-chart {
  background: rgba(255, 255, 255, 0.72);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(56px);
  -webkit-backdrop-filter: blur(56px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 12px;
  padding: 32px 40px 0px 40px;
  color: #000000;
  box-sizing: border-box;
  &.performance {
    height: 444px;
    width: 660px;
    & .graph-wrapper {
      width: 580px;
      height: 190px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.video-performance {
    // background: rgba(0, 0, 0, 0.04);
    width: 470px;
    grid-row: 1 / span 2;
    grid-column: 2;
    box-sizing: border-box;

    & .dstats-card-wrapper {
      margin-top: 40px;
      display: flex;
      column-gap: 24px;

      & .dstats-card {
        width: 182px;
        height: 200px;
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(56px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 12px;
        padding: 24px;
        box-sizing: border-box;
        &:nth-child(1):hover {
          -webkit-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
          box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.3);
          background: rgba(255, 255, 255, 0.7);
        }
        &__header {
          display: flex;
          justify-content: space-between;
          // margin-bottom: 40px;
          margin-bottom: 62px;
          & .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.04);
            @include center-content;
            & img {
              width: 25px;
              height: 25px;
            }
            &.more-icon {
              width: fit-content;
              background: none;
              cursor: pointer;
              & img {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
        // &.watch-time {
        // 	& .dstats-card__header {
        // 		margin-bottom: 62px;
        // 	}
        // }
      }
    }

    & .video-per-list {
      &-wrapper {
        margin-top: 45px;
      }
    }

    & .videos-sort__header {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 10px 4px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
      & .sort-criteria {
        position: relative;
        & img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: -3px;
        }
      }
    }

    & .pr-videos-list {
      margin-top: 0px;
      overflow-x: hidden;
      overflow-y: auto;
      height: 360px;
      &::-webkit-scrollbar {
        width: 2px !important;
        display: block !important;
      }
      &::-webkit-scrollbar-thumb {
        width: 2px !important;
        border-radius: 10px;
        background-color: #adadad;
      }
      &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 2px !important;
      }
    }
  }

  &__params {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    & .legend-wrap {
      align-self: center;

      & .legend {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: rgba(13, 13, 18, 0.46);
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        &.one::before {
          background: #000000;
        }
        &.two::before {
          background: #00dc58;
        }
      }
    }
  }

  &.traffic-sources {
    width: 660px;
    height: 272px;
    padding-top: 32px;
    position: relative;
    cursor: pointer;
    &:hover {
      -webkit-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
      box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.3);
      background: rgba(255, 255, 255, 0.7);
    }
    & .traffic-sources__more {
      cursor: pointer;
      position: absolute;
      top: 34px;
      right: 48px;
      & img {
        width: 15px;
      }
    }
    & .graph-wrapper {
      width: 100%;
      height: 170px;
      position: relative;
      pointer-events: none;
      cursor: pointer;
      // padding-top: 32px;
    }
  }
}

.title-one {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  margin-bottom: 32px;
}

.param {
  display: flex;
  &-icon {
    @include center-content;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.04);
    margin-right: 16px;
    & img {
      height: 25px;
      width: 25px;
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    & .param-stat {
      font-size: 26px;
      line-height: 26px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
      & .perc {
        font-size: 16px;
        line-height: 22px;
        display: inline-block;
        margin-left: 2px;
      }
    }

    & .param-name {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);
    }

    & .param-progress {
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      display: flex;
      align-items: center;
      & img {
        width: 6px;
        height: 6px;
        margin-left: 5px;
      }
      &.down {
        color: #e00000;
      }
      &.up {
        color: #00dc58;
      }
    }
  }
}

.loading-div {
  position: absolute;
  top: 50%;
  left: 50%;
}

.line-graph-wrapper {
  height: 240px;
  margin-top: 50px;
  margin-left: -40px;
}

.custom-tooltip {
  // height: 115px;
  max-height: 115px;
  box-sizing: border-box;
  background: white;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  color: black;
  padding: 8px 16px;
  border-radius: 8px;
  .custom-tooltip-date {
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    color: rgba(18, 17, 28, 0.36);
  }
  h1 {
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    margin: 0;
    margin-top: 8px;
    letter-spacing: 0.4px;
  }
  p {
    font-size: 10px;
    line-height: 16px;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Loading
.loading-product-performance-topbar {
  width: 120px;
  height: 16px;
  border-radius: 6px;
}
.loading-product-performance-items-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  .loading-product-performance-item {
    display: flex;
    width: 40%;
    .loading-product-performance-circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      margin-right: 15px;
    }
    .loading-product-performance-rect-wrapper {
      display: flex;
      flex-direction: column;
      .loading-product-performance-rect-one {
        width: 48px;
        height: 24px;
        border-radius: 6px;
      }
      .loading-product-performance-rect-two {
        margin-top: 6px;
        width: 88px;
        height: 12px;
        border-radius: 6px;
      }
    }
  }
}
.loading-product-performance-bottombar {
  margin-top: 40px;
  width: 100%;
  height: 236px;
  border-radius: 6px;
}

.video-performance-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .video-performance-loading-circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }
  .video-performance-loading-rect-one {
    width: 48px;
    height: 24px;
    border-radius: 6px;
  }
  .video-performance-loading-rect-two {
    margin-top: 6px;
    width: 88px;
    height: 12px;
    border-radius: 6px;
    margin-bottom: 5px;
  }
}

// Traffic Performance Loading
.traffic-performance-loading {
  width: 100%;
  height: 90%;
  .traffic-performance-loading-rect-one {
    width: 120px;
    height: 16px;
    border-radius: 6px;
  }
  .traffic-performance-loading-rect-two {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
