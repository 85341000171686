.appendImg {
  top: 22px;
  height: 30px;
}

.search-bar-new {
  font-size: 60px !important;
  height: auto !important;
  background-color: transparent;
  box-shadow: none !important;
  padding: 0px 40px 0 0 !important;
}

// Small Search Bar

.search-bar {
  font-size: 13px;
  border-radius: 5px;
  width: 100%;
  min-width: auto;
  height: 43px;
  padding-left: 40px;
  padding-right: 35px;
  border: none;
  box-sizing: border-box;
  font-weight: 900;
  color: black;
  -webkit-box-shadow: 0px 7.7px 15.42px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 7.7px 15.42px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 7.7px 15.42px rgba(0, 0, 0, 0.05);
  outline: none;
}

.prependImg {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.appendImgSmall {
  top: 50%;
  right: 1%;
  transform: translate(-50%, -50%);
}
