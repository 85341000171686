.plan-usage-estimate-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 48px;
  & .hour-glass__wrapper {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #d2d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 32px;
      height: 32px;
    }
  }
  & .tier-status {
    margin-top: 16px;
    text-align: center;
    &__title {
      font-size: 24px;
      line-height: 28px;
      color: #000000;
      font-weight: 700;
    }
    &__msg {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: rgba(0,0,0,0.5);
      width: 312px;
      margin-top: 12px;
      text-align: center;
      &.red-text {
        width: 535px;
        color: #e00000;
      }
    }
  }
  & .usage-container {
    display: flex;
    column-gap: 30px;
    margin-top: 40px;
  }
  & .estimate-container {
    margin-top: 67px;
    & .msg {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: rgba(0,0,0,0.5);
    }
    & .pricing-container {
      margin-top: 20px;
      text-align: center;
      & .amount-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        & span {
          display: block;
          font-weight: 700;
          line-height: 100%;
          color: #000000;
        }
        & .currency-icon {
          font-size: 36px;
          margin-bottom: 6px;
          margin-right: 4px;
        }
        & .amount {
          font-size: 64px;
        }
      }
      & .amount-period {
        margin-top: 4px;
        color: #000000;
        font-size: 14px;
        line-height: 100%;
        font-weight: 700;
      }
    }
  }
  & .subscribe-wrapper {
    margin-top: 60px;
    width: 100%;
    height: 152px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.32);
    backdrop-filter: blur(20px);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    & .subscribe-msg {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0,0,0,0.5);
    }
    & .subscribe-btn-wrapper {
      margin-top: 20px;
      width: 236px;
      height: 48px;
      background: #000000;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      & .btn {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
        }
        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
}

.sign-out-btn {
  width: 65px;
  height: 12px;
  position: absolute;
  top: -20px;
  right: 5%;
  & button {
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.88);
    text-transform: uppercase;
    cursor: pointer;
  }
}