.planusage-card {
  box-sizing: border-box;
  width: 155px;
  height: 154px;
  background: rgba(255, 255, 255, 0.72);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(56px);
  border-radius: 12px;
  padding: 20px 0 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .icon-wrapper {
    width: 36px;
    height: 36px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 20px;
      height: 20px;
      // object-fit: cover;
    }
  }
  & .details {
    margin-top: 24px;
    & .value-wrapper {
      font-size: 26px;
      line-height: 26px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
      & span {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        color: #000000;
      }
    }
    & .msg {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      text-transform: uppercase;
    }
  }
}