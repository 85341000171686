.signin-new {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .sso-tac-heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    align-self: flex-start;
    margin-bottom: 20px;
  }
  .important-note-wrapper {
    font-size: 13px;
    line-height: 15.85px;
    font-weight: 700;
  }
  .email-inpt {
    label {
      font-weight: 700;
      font-size: 10px;
      line-height: 12.19px;
      text-transform: uppercase;
      color: #3a3a3a;
    }
    .input-wrap {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #bbbbbb;
      padding: 6px;
      border-radius: 8px;
      .input-image {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 100%;
        box-shadow: 0px 10px 30px 0px #00000040;
        margin-left: 10px;
        img {
          width: 100%;
        }
      }
      .email-custom {
        width: 80%;
        overflow: hidden;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    opacity: 0.1;
    font-size: 12px;
    &:before {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
    }
    &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
    }
  }
  .separator:not(:empty)::before {
    margin-right: 1em;
  }

  .separator:not(:empty)::after {
    margin-left: 1em;
  }
  // OTP
  .otp-title {
    font-size: 10px;
    line-height: 12px;
    margin: 0;
    font-weight: 700;
    margin-bottom: 5px;
    opacity: 0.5;
  }
  .otp-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    span {
      font-weight: 700;
    }
  }

  .back-btn {
    position: absolute;
    height: 32px;
    width: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 100%;
    box-shadow: 0px 10px 30px 0px #00000040;
    left: 20px;
    top: 24px;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .otp-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .otp-input {
    height: 40px;
    min-height: 40px;
    width: 38px;
    min-width: 38px;
    padding: 0;
  }
  .otp-error {
    border: 1px solid #e00000;
  }
  .otp-error-text {
    color: #e00000;
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
  }

  .otp-resend-btn {
    width: 100%;
    height: 50px;
    background-color: black;
    color: white;
    font-family: 'Montserrat';
    border-radius: 6px;
    font-size: 12px;
    outline: none;
    border: 0px;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
    &:disabled {
      cursor: none;
      opacity: 0.2;
    }
  }
}

.sign-in{
  display: flex;
  column-gap: 8px;
  position: absolute;
  top: calc(3px + 5%);
  right: 5%;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.6);
  // color: #000000;
  z-index: 100000;
  & .btn-wrapper {
    width: 40px;
    height: 12px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.88);
    // color: #000;
    & .btn {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
    }
  }
}