.rc-slider {
  position: relative;
  touch-action: none;
  box-sizing: border-box;
  width: 332px;
  height: 12px !important;
  background: #F1F1F1;
  border: 2px solid #FFFFFF;
  box-shadow: inset 0px 3.375px 4.5px rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  border: 2px solid #FFFFFF;
/* background: linear-gradient(90deg, #4B4B4B 1.41%, #000000 66.17%, #4425F5 100.1%); */
  /* -webkit-tap-highlight-color: rgba(0,0,0,0); */
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 100%;
  border-radius: 6px;
  background-color: tint(#2db7f5,60%);
  background: linear-gradient(90deg, #4B4B4B 1.41%, #000000 66.17%, #4425F5 100.1%);
  position: absolute;
  top: 0px;
  left: 0px;
}
.rc-slider-handle {
  position: absolute;
  width: 28px;
  height: 28px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  /* background-color: #fff; */
  touch-action: pan-x;
  background: center / contain no-repeat url('../../assets/slider-handle.svg');
  position: absolute;
  top: -4px;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: tint(#2db7f5,20%);
  box-shadow: 0 0 0 5px tint(#2db7f5,50%);
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: tint(#2db7f5,50%);
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: tint(#2db7f5,20%);
}
.rc-slider-handle:active {
  border-color: tint(#2db7f5,20%);
  box-shadow: 0 0 5px tint(#2db7f5,20%);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
  opacity: 0;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  opacity: 0;
  pointer-events: none;
}
.rc-slider-dot-active {
  border-color: tint(#2db7f5,50%);
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text, .rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active, .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear {
  transform: scale(0,0);
  animation-timing-function: cubic-bezier(0.23,1,0.32,1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755,0.05,0.855,0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0,0);
 }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1,1);
 }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1,1);
 }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0,0);
 }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 140px;
  height: 32px;
  color: #4425F5;
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;color: #4425F5;
  text-align: center;
  text-decoration: none;
  background-color: tint(#666,4%);
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: tint(#666,4%);
}



.nos {
  width: 150px;
  height: 32px;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #4425F5;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nos span {
  font-weight: 600;
  font-size: 10px;
  display: block;
  margin-left: 4px;
}

.rc-slider-handle.rc-slider-handle-1 {
  display: none !important;
  opacity: 0 !important;
}

.rc-slider {
  pointer-events: none;
  .rc-slider-handle {
      pointer-events: auto;
  }
}

.rc-slider-tooltip {
  width: 150px;
  height: 32px;
  box-sizing: border-box;
  padding: 0px !important;
  & .rc-slider-tooltip-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 0; 
      height: 0; 
      top: 32px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
    }
    & .rc-slider-tooltip-inner{
      width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
  }
}