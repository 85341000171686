.stats-card-heatmap {
  position: relative;
  width: 345px;
  min-width: 345px;
  height: 272px;
  min-height: 272px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
  &:hover {
    -webkit-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 1);
  }
  .stats-card-heatmap-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .stats-card-heatmap-header {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      .icon-wrapper {
        height: 35px;
        width: 35px;
        border-radius: 100%;
        margin-right: 15px;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
    .stats-card-heatmap-graph {
      width: 100%;
      height: auto;
      margin-top: 8px;
      padding-left: 25px;
      .stats-card-heatmap-grid {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 0;
        align-items: center;
        .stats-card-heatmap-grid-cell {
          width: 25px;
          padding: 0;
          font-size: 10px;
          font-weight: 600;
          opacity: 0.5;
        }
        .stats-card-heatmap-grid-cell-color {
          height: 20px;
          width: 20px;
          margin: 1px;
          padding: 0;
          animation: fadeIn 0.5s ease-in-out;
        }
        .stats-card-heatmap-grid-cell-x-axis {
          height: auto;
          width: 20px;
          margin: 1px;
          padding: 0;
          font-size: 9px;
          font-weight: 600;
          opacity: 0.5;
          text-align: center;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
