.statspopup-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.18);

  .stats-popup {
    width: 823px;
    max-width: 823px;
    height: 622px;
    max-height: 622px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .stats-popup-header {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .stats-popup-title {
        display: flex;
        align-items: center;

        padding: 20px;
        button {
          border-radius: 100%;
          width: 32px;
          height: 32px;
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.329);
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        h1 {
          font-size: 18px;
          font-weight: 700;
          margin-left: 20px;
        }
      }
    }
    .stats-popup-calendar-btn {
      margin-right: 20px;
      padding: 15px 20px;
      background-color: black;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 700;
      color: white;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
    .stats-popup-last-updated {
      font-size: 10px;
      line-height: 10px;
      font-weight: 600;
      opacity: 0.2;
      position: absolute;
      right: 20px;
      top: 100px;
    }

    .product-details {
      width: 100%;
      margin-top: 24px;
      display: flex;
      align-items: center;
      column-gap: 12px;
      padding-left: 65px;
      padding-bottom: 28px;
      box-sizing: border-box;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
      & .product-img {
        margin-left: 21px;
        width: 42px;
        height: 56px;
        min-width: 42px;
        min-height: 56px;
        background: transparent;
        box-shadow: 0px 7.18635px 14.3727px rgba(0, 0, 0, 0.2);
        border-radius: 6px;

        & img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }
      }
      & .product-name {
        margin-left: 12px;

        & .title {
          font-size: 10px;
          line-height: 12px;
          font-weight: 700;
          text-transform: uppercase;
          color: #bfbfbf;
        }

        & .value {
          margin-top: 6px;
          text-transform: capitalize;
          color: #000;
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
        }
      }
      & .hr-break {
        width: 100%;
        border: 0.5px solid rgba(0, 0, 0, 0.15);
        margin: 16px 0 0px 0 !important;
      }
    }

    .skill-stat-wrapper {
      width: 100%;
      box-sizing: border-box;
      padding: 24px 0px;
      padding-left: 80px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
      .skill-stat-wrapper-content-main {
        display: flex;
        .stats-details {
          display: flex;
          align-items: center;
          width: 200px;
          box-sizing: border-box;
          position: relative;
          // border-right: 1px solid #000;
          // margin-right: 20px;
          .skill-poster {
            width: 56px;
            min-width: 56px;
            min-height: 56px;
            height: 56px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-right: 16px;
            // overflow: hidden;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.33);
            & img {
              object-fit: cover;
              width: 100%;
              border-radius: 50%;
              height: 100%;
            }
          }
          .skill-stat-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.04);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 25px;
              height: 25px;
            }
          }
          .skill-stats {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            margin-left: 16px;
            .skill-name {
              margin-bottom: 4px;
            }
            .skill-counts {
              display: flex;
              align-items: center;
              img {
                width: 25px;
                height: 25px;
                margin-right: 4px;
              }
            }

            .stat-value {
              font-size: 26px;
              line-height: 26px;
              .perc {
                font-size: 16px;
                line-height: 22px;
                margin-left: 4px;
              }
            }
            .stat-name {
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.5);
            }
          }
          .sep {
            width: 64px;
            height: 1px;
            box-sizing: border-box;
            border: 1px solid;
            border-image-source: linear-gradient(
              270deg,
              rgba(196, 196, 196, 0) 0%,
              #c4c4c4 50%,
              rgba(196, 196, 196, 0) 100%
            );
            transform: rotate(90deg);
            position: absolute;
            right: 20px;
            top: 20px;
          }
          &:last-child {
            margin-right: 0;
            border-right: none;
          }
        }
      }
    }

    .stats-popup-content-wrapper {
      width: 80%;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      .stats-popup-content-main {
        display: flex;
        align-items: center;
        .icon-wrapper {
          height: 40px;
          width: 40px;
          border-radius: 100%;
          margin-right: 15px;
          background-color: rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 25px;
            width: 25px;
          }
        }
        .stats-popup-content {
          h1 {
            margin: 0;
            font-size: 26px;
            font-weight: 700;
          }
          span {
            font-size: 16px;
            font-weight: 700;
          }
          h2 {
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            opacity: 0.5;
          }
        }
      }
      .stats-popup-content-tagline {
        display: flex;
        align-items: center;
        .circle {
          width: 8px;
          height: 8px;
          background-color: black;
          border-radius: 100%;
        }
        h2 {
          margin: 0;
          font-size: 10px;
          line-height: 12px;
          font-weight: 700;
          opacity: 0.5;
          margin-left: 10px;
        }
      }
    }
    .stats-popup-graph-wrapper {
      width: 85%;
      margin-top: 20px;
      margin-left: -40px;
      &.product {
        & .date-legend {
          right: 35px !important;
          bottom: 45px !important;
        }
      }
      & .date-legend {
        font-size: 10px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 600;
        position: absolute;
        right: 35px;
        bottom: 65px;
      }
    }

    // Calendar Pop Up
    .stats-calendar-popup {
      position: absolute;
      z-index: 10;
      top: 80px;
      right: 50px;
      border-radius: 6px !important;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.5);
      button {
        margin: 0px !important;
      }
      .stats-calendar {
        background-color: rgb(239, 242, 247);
        border-radius: 6px !important;
        .rdrDateDisplayWrapper {
          background: rgba(243, 243, 243, 1);
        }
        .rdrEndEdge {
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
        }
        .rdrStartEdge {
          border-top-left-radius: 6px !important;
          border-bottom-left-radius: 6px !important;
        }
        .rdrDay {
          height: 3.5em !important;
        }
        .rdrDayToday .rdrDayNumber span {
          &:after {
            background: #fff;
          }
        }
        .rdrDayStartPreview {
          border-top-left-radius: 6px !important;
          border-bottom-left-radius: 6px !important;
        }
        .rdrDayEndPreview {
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
        }
        .rdrInputRangeInput {
          border-radius: 6px !important;
          padding: 0 !important;
        }
        .rdrNextPrevButton {
          border-radius: 100% !important;
          background-color: white;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        }
        & .rdrInputRanges {
          & .rdrInputRange:nth-child(2) {
            display: none !important;
          }
        }
        & .rdrMonthAndYearWrapper {
          padding: 0 10px;
        }
      }

      .stats-calendar-apply {
        width: 100%;
        height: 84px;
        background: rgba(224, 224, 224, 1);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        .stats-calendar-apply-btn-wrapper {
          position: absolute;
          right: 20px;
          .stats-calendar-apply-btn {
            font-size: 12px;
            font-weight: 700;
            margin-right: 30px !important;
            cursor: pointer;
          }
          .stats-calendar-apply-btn-fill {
            font-size: 12px;
            font-weight: 700;
            background-color: black;
            color: white;
            padding: 15px;
            border-radius: 6px;
            // cursor: pointer;
          }
        }
      }
    }
  }
}

.custom-tooltip {
  background: white;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  color: black;
  padding: 5px 15px;
  border-radius: 10px;
  .custom-tooltip-date {
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    opacity: 0.5;
  }
  h1 {
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
    margin: 0;
  }
  p {
    font-size: 10px;
    line-height: 16px;
    margin: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.skill-stats-graph-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 60px;
  margin-top: 30px;
  position: relative;
  h1 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 50px;
  }
  & .graph-wrapper {
    // width: 580px;
    height: 230px;
    margin: 0 auto;
    position: relative;
  }
}

.traffic-source-wrapper {
  margin-top: 30px;
  height: 100%;
  width: 80%;
  position: relative;
  box-sizing: border-box;
  overflow-y: scroll;
}

.recharts-layer.recharts-line-dots {
  clip-path: none !important;
}

// loading wrapper graph
.loading-wrapper-graph {
  width: 100%;
  height: 100%;

  .loading-top-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 28px;
    left: 0;
    .loading-circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }

    .loading-right {
      margin-left: 10px;
      .loading-right-top {
        width: 48px;
        height: 24px;
        border-radius: 6px;
      }
      .loading-right-bottom {
        margin-top: 5px;
        width: 88px;
        height: 12px;
        border-radius: 6px;
      }
    }
  }
  .loading-bottom-wrapper {
    margin-top: 40px;
    width: 658px;
    max-width: 658px;
    min-width: 658px;
    height: 365px;
    border-radius: 8px;
  }
}

// Loading Wrapper Graph Video Performance

.loading-wrapper-graph-videoperformance {
  width: 823px;
  min-width: 823px;
  display: flex;
  flex-direction: column;
  .loading-top-wrapper-videoperformance {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    left: 0;
    margin-left: 85px;
    .loading-square {
      width: 42px;
      height: 56px;
      border-radius: 6px;
    }
    .loading-circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
    .loading-right {
      margin-left: 10px;
      .loading-right-top {
        width: 60px;
        height: 12px;
        border-radius: 4px;
      }
      .loading-right-bottom {
        margin-top: 5px;
        width: 92px;
        height: 12px;
        border-radius: 6px;
      }
    }
  }
  .one-videoperformance {
    margin-top: 30px;
  }
  .two-videoperformance {
    margin-top: 30px;
  }

  .loading-videoperformance-border {
    height: 0.5px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 30px;
  }
  .loading-bottom-wrapper-videoperformance {
    margin-top: 50px;
    margin-left: 85px;
    width: 658px;
    max-width: 658px;
    min-width: 658px;
    height: 320px;
    max-height: 280px;
    border-radius: 8px;
  }
}

.loading-traffic-source-wrapper {
  margin-top: 10px;
  width: 720px;
  height: 446px;
  border-radius: 8px;
}
.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 28%, #ececec 40%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
