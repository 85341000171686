.stats-card {
  width: 345px;
  min-width: 345px;
  height: 200px;
  min-height: 200px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.72);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 24px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 40px;
  &:hover {
    -webkit-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 1);
  }
  .stats-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .stats-card-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      .stats-card-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        .icon-wrapper {
          height: 35px;
          width: 35px;
          border-radius: 100%;
          margin-right: 15px;
          background-color: rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 20px;
            width: 20px;
          }
        }
      }
      img {
        height: 12px;
        width: 11px;
      }
    }
    .stats-card-main {
      margin: 0;
      width: 100%;
      .stats-card-graph-content {
        h1 {
          font-size: 24px;
          font-weight: 700;
          margin: 0;
          .perc {
            font-size: 16px;
            line-height: 22px;
            margin-left: 4px;
          }
        }
        h2 {
          margin: 0;
          margin-top: 5px;
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.2);
          font-weight: 600;
        }
      }
    }
  }
  .stats-card-graph-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50px;
    left: 200px;
    pointer-events: none;
    cursor: pointer;
  }
}
