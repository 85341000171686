.gButton {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  text-align: left;
  font-family: Montserrat;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
}

.gDiv {
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 2px;
  margin-right: 10px;
}

.gText {
  font-weight: 800;
}
