.select-input {
  select {
    /* inline SVG */
    background-image: url('../../assets//caret-down.svg');
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 30%;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}
.drag-presentation {
  div[role='presentation'] {
    margin-left: -50px;
    width: 100%;
  }
}
.product-card-wrapper {
  .edit-btn-group {
    display: flex;
    .button-link {
      background-color: black;
      padding: 9px 15px;
      color: white;
      font-size: 12px;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
      font-weight: 700;
      text-transform: uppercase;
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .button-delete-link .loader-animation svg {
      width: 14px;
      height: 14px;
      margin-top: 2px;
      fill: rgba(255, 0, 0, 0.56) !important;
    }
  }
  .total-videos {
    color: #fff;
    border-radius: 35px;
    padding: 4px 8px;
    background-color: rgba(107, 107, 107, 0.72);
    font-size: 10px;
    display: block;
    text-align: center;
    font-weight: 800;
    align-self: flex-start;
    margin: 15px 0 0 30px;
    text-transform: uppercase;
  }
  span.click-drag-text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    font-weight: 700;
    color: #b4b4b4;
    top: 61.6%;
  }
  .video-container {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    padding: 20px 25px 35px 25px;
    min-height: 317px;
    &::-webkit-scrollbar {
      height: 2px !important;
      display: block !important;
    }
    &::-webkit-scrollbar-thumb {
      width: 2px !important;
      border-radius: 10px;
      background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
      background: #dcdcdc !important;
      width: 8px !important;
    }
    &-item {
      position: relative;
      width: 180px;
      min-width: 180px;
      height: 315px;
      border-radius: 10px;
      box-shadow: 0px 10px 30px 0px #00000026;
      background-color: white;
      margin-right: 20px;
      background-size: cover;
      background-position: center;
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.56);
        color: white;
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        width: 110px;
        height: 30px;
      }
      &-desc {
        z-index: 2;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        align-items: center;
        background-color: #fff;
        img {
          margin-left: 4px;
          cursor: pointer;
          padding: 11px;
        }
        input {
          outline: none;
          font-size: 12px;
          box-shadow: none;
          background-color: transparent;
          color: #000;
          height: auto;
          width: auto;
          border: unset;
          padding: 1px;
          &::-webkit-input-placeholder {
            color: #000;
          }
        }
      }
      &-dur {
        position: absolute;
        bottom: 50px;
        left: 15px;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
      }
    }
    &-item:nth-child(1) {
      margin-left: 30px;
    }
  }
  .bottom-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 263px;
    background: rgba(255, 255, 255, 0.32);
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .upload-info {
      position: relative;
      width: 36%;
      margin: 0 10px 0 35px;
      padding: 20px;
      // border: 1px solid black;
      border-radius: 10px;
      margin-right: 30px;
      & .product-cost {
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 18px;
        font-weight: 800;
        & .currency-type {
          color: rgba(0, 0, 0, 1);
          font-size: 14px;
          font-weight: 700;
          margin-right: 4px;
        }
      }
      & .product-name {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        color: #000;
        margin-top: 8px;
      }
      img.product-edit-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      h1 {
        font-size: 18px;
        font-weight: bolder;
        opacity: 0.5;
        margin: 0;
      }
      div {
        display: flex;
        .select-input {
          width: auto;
          select {
            height: auto;
            margin: 0;
            box-shadow: unset;
            background-color: transparent;
            cursor: pointer;
            padding: 0 20px 0 0;
          }
        }
        span {
          font-size: 14px;
          opacity: 0.5;
          font-weight: 700;
          margin-right: 4px;
          align-self: flex-end;
        }
        input {
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: 700;
          outline: none;
          box-shadow: none;
          background-color: transparent;
          color: rgba(0, 0, 0, 0.5);
          width: auto;
          border: unset;
          height: auto;
        }
        input::placeholder {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.5);
        }
        input:disabled {
          cursor: unset;
          background: transparent !important;
        }
      }
      .edit-products-input {
        padding-left: 1px !important;
        height: auto;
        margin: 10px 0;
        padding: 0;
        font-size: 12px;
        font-weight: 700;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        color: #000;
        width: auto;
        border: unset;
      }
      input::placeholder {
        font-size: 12px;
        color: black;
      }
      input:disabled {
        cursor: unset;
        background: transparent !important;
      }
      .hashtags-info-wrapper {
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        row-gap: 8px;
        margin-top: 20px;
        & .hashtag {
          display: flex;
          align-items: center;
          margin-left: 0px;

          & .hashtag-info img {
            margin-right: 6px;
          }
        }
        .hashtag-info {
          font-weight: 400;
          height: auto;
          padding: 10px;
          font-size: 10px;
          outline: none;
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.05) !important;
          color: #000;
          width: auto;
          border: unset;
          border-radius: 8px;
          margin-right: 5px;
          min-width: 50px;
          &:first-child {
            margin-left: 0px;
          }
          .hashtag-edit-input {
            font-weight: 400;
            height: auto;
            padding: 10px;
            font-size: 10px;
            outline: none;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.05) !important;
            color: #000;
            width: auto;
            border: unset;
            border-radius: 8px;
            margin-right: 5px;
            min-width: 50px;
          }
          .hashtag-edit-input::placeholder {
            font-size: 10px;
            color: black;
          }
        }
      }
    }
    .magic-pair-container {
      max-height: 400px;
      width: 47%;
      align-self: center;
      .magic-pair-heading {
        display: flex;
        img {
          height: 10px;
          margin-right: 8px;
        }
        h4 {
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          color: #000;
          margin: 0;
        }
      }
      .magic-pair-input {
        margin-top: 12px;
        max-height: 182px;
        min-height: 60px;
        padding-right: 5px;
        &::-webkit-scrollbar {
          width: 2px !important;
          display: block !important;
        }
        &::-webkit-scrollbar-thumb {
          width: 2px !important;
          border-radius: 10px;
          background-color: #adadad;
        }
        &::-webkit-scrollbar-track {
          background: #dcdcdc !important;
          width: 2px !important;
        }

        & div:not(:last-child) {
          margin-bottom: 6px;
        }

        .input-container {
          position: relative;

          &.has-error {
            & input {
              border: 1px solid #e00000 !important;
            }
          }

          input {
            border: 1px solid #dfdfdf;
            background-color: #fff;
            box-shadow: unset;
            border-radius: 6px;
            padding-right: 50px;
            &::-webkit-input-placeholder {
              color: rgba(0, 0, 0, 0.5);
            }
          }
          div {
            position: absolute;
            top: 50%;
            right: -5px;
            transform: translate(-50%, -50%);
            img {
              opacity: 0.6;
              cursor: pointer;
              margin: 4px 5px 0 0;
            }
          }

          &__error {
            display: block;
            margin-top: 4px;
            color: #e00000;
            font-size: 10px;
            line-height: 12px;
            font-weight: 600;
            padding-left: 12px;
          }
        }
        .input-container:not(:first-child) {
          margin-top: 8px;
        }
      }
      .magic-pair-add {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        cursor: pointer;
        span {
          font-size: 10px;
          font-weight: 700;
          margin-left: 8px;
        }
      }
    }
    button {
      position: absolute;
      right: 20px;
      bottom: 20px;
      background-color: black;
      border: none;
      color: white;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      height: 40px;
      width: 120px;
      border-radius: 6px;
      &:disabled {
        background-color: #bfbfbf;
      }
    }
  }
}

.add-v-scroll {
  overflow-y: scroll;
}

.editable {
  cursor: pointer;
}
