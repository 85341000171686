// Pop Ups
.popups-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.18);

  .product-card-dragactive {
    border: 1px solid #0000ff;
  }

  .product-card-wrapper {
    width: 800px;
    min-width: 800px;
    max-width: 950px;
    height: 570px;
    min-height: 680px;
    max-height: 760px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    padding: 25px;
    border-radius: 6px;
    background: rgba(225, 225, 225, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      position: relative;
      justify-content: space-between;
      // Back Button
      .back-button {
        height: 34px;
        width: 34px;
        cursor: pointer;
        z-index: 100;
        border-radius: 100%;
        box-shadow: 0px 5px 10px 0px rgba($color: #000000, $alpha: 0.2);

        img {
          height: 34px;
          width: 34px;
          cursor: pointer;
        }
      }

      // Current Step Dots
      .current-step-wrapper {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        justify-self: center;
        .circle {
          height: 6px;
          width: 6px;
          background-color: black;
          opacity: 0.12;
          border-radius: 100%;
          margin: 0px 5px;
          transition: all 0.2s ease-in-out;
        }
        .active {
          opacity: 1;
          background-color: black;
        }
      }

      // Add More Button
      .add-more-btn-wrapper {
        position: absolute;
        right: 0px;
      }
      .add-more-btn {
        width: 100px;
        height: 32px;
        cursor: pointer;
        z-index: 100;
        border-radius: 6px;
        border: 1px solid #0000008f;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 10px;
          width: 10px;
          margin-right: 5px;
        }
      }
    }
    // Current Screen title
    .current-screen-title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .total-videos-title {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 38px;
        left: 0;
        width: 67px;
        height: 20px;
        max-width: 67px;
        max-height: 20px;
        font-size: 10px;
        line-height: 11px;
        color: #fff;
        background-color: #6b6b6bb8;
      }
    }

    // Product Details Container

    .product-actions-container {
      overflow-y: scroll;
      margin-top: 20px;
      height: 100%;
      width: 70%;
      .product-actions-input {
        padding: 10px;
      }
      .optional-wrapper {
        margin-top: 10px;
        padding: 10px;
        .price-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .price-inpt-wrapper {
            display: flex;
          }
        }

        .optional-field {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          .title {
            margin: 0;
            margin-left: 10px;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            cursor: pointer;
          }
        }
        .optional-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background-color: rgba($color: #000000, $alpha: 0.2);
          transition: all 0.2s ease-in-out;
          &:hover {
            transform: scale(0.9);
          }
          img {
            width: 8px;
          }
        }
        .select-input {
          width: 30%;
          select {
            height: 40px;
            width: 100%;
            margin: 5px 0px;
            padding: 10px;
            border-radius: 6px;
            min-width: auto;
            border: none;
            font-size: 12px !important;
            font-weight: 900;
            color: black;
            -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            outline: none;
            option {
              height: 40px;
              border: 0px;
              margin: 5px 0px;
              padding: 10px;
            }
          }
        }

        .hashtags-container {
          overflow: hidden;
          overflow-y: scroll;
          height: 330px;
          .hashtags-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .title {
              font-weight: 700;
              font-size: 16px;
            }
          }
          p {
            font-weight: 600;
            font-size: 12px;
            opacity: 0.5;
          }
          .hashtags-wrapper {
            margin-bottom: 10px;
            .hashtags-title {
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              margin-bottom: 10px;
            }
            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
              padding-bottom: 10px;
              padding-top: 10px;
              .hashtag-title-wrapper {
                display: flex;
                align-items: center;
                .hash-text {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 16px;
                  margin-left: 20px;
                }
                .title-input {
                  width: 400px;
                  border: none !important;
                  background-color: transparent;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 16px;
                  box-shadow: none;
                  margin: 0;
                  padding: 0;
                  margin-left: 5px;
                  border-radius: 0;
                }
                .drag-handle-btn {
                  width: 24px;
                  height: 24px;
                  img {
                    width: 24px !important;
                    height: 24px !important;
                  }
                }
              }

              .close-btn {
                height: 15px;
                width: 15px;
                cursor: pointer;
                z-index: 100;
                border-radius: 100%;
                img {
                  height: 15px;
                  width: 15px;
                  cursor: pointer;
                }
              }
            }
            .add-hashtag-wrapper {
              display: flex;
              align-items: center;
              margin-top: 20px;
              width: fit-content;
              cursor: pointer;
              .title {
                margin: 0;
                margin-left: 10px;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .bottom-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        background-color: rgba(255, 255, 255, 0.32);
        width: 100%;
        z-index: 100;
        button {
          position: absolute;
          right: 30px;
          top: 30px;
          background-color: black;
          border: none;
          color: white;
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          height: 40px;
          width: 120px;
          border-radius: 6px;
          &:disabled {
            background-color: #bfbfbf;
          }
        }
      }
    }
  }

  // Upload Video Container
  .upload-video-container {
    width: calc(100% + 50px);
    height: 60%;
    min-height: 410px;
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 2px !important;
      display: block !important;
    }
    &::-webkit-scrollbar-thumb {
      width: 2px !important;
      border-radius: 10px;
      background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
      background: #dcdcdc !important;
      width: 8px !important;
    }

    .add-more-card {
      width: 202px;
      min-width: 200px;
      max-width: 200px;
      height: 360px;
      min-height: 360px;
      max-height: 360px;
      transform: scale(0.85);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      img {
        height: 50px;
        width: 50px;
        opacity: 0.4;
      }
    }

    .upload-video-icon-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Uploading Video Icon
    .upload-video-icon {
      height: 320px;
      width: 190px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      opacity: 0.5;
      transform: scale(0.9);
      img {
        height: 40px;
        width: 40px;
        opacity: 0.3;
      }
      .title {
        position: absolute;
        bottom: 10px;
        padding: 0px 10px;
        text-align: center;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
      }
    }

    // Styles for Uploading Video Wrapper
    .videos-wrapper {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0 0 0 25px;
    }

    // Bottom wrapper for uploading Video
    .bottom-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 30%;
      background-color: rgba(255, 255, 255, 0.32);
      width: 100%;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      .upload-info {
        width: 90%;
        h1 {
          font-size: 18px;
          font-weight: bolder;
          opacity: 0.5;
          margin: 0;
        }
        p {
          margin: 0;
          margin-top: 5px;
          font-size: 12px;
          font-weight: 700;
          width: 40%;
          color: black;
        }
        .hashtags-info-wrapper {
          display: flex;
          margin: 0;
          margin-top: 10px;
          width: 40%;
          flex-wrap: wrap;
          .hashtag-info {
            display: flex;
            align-items: center;
            margin-left: 5px;
            margin-top: 5px;
            &:first-child {
              margin-left: 0px;
            }
            .title {
              padding: 10px;
              margin-right: 5px;
              font-size: 10px;
              line-height: 12px;
              border-radius: 8px;
              background-color: rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
      button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        background-color: black;
        border: none;
        color: white;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 40px;
        width: 120px;
        border-radius: 6px;
        &:disabled {
          background-color: #bfbfbf;
        }
      }
    }
  }

  // Final Step Constainer
  .final-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    margin-bottom: 100px;

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      opacity: 0.5;
      margin: 0;
      margin-top: 5px;
    }

    .product-card-dummy {
      margin-top: 15px;
      width: 175px;
      height: 305px;
      min-height: 305px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      border-radius: 18px;
      padding: 10px;
      border: 1px solid #fff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .videos-container {
        height: 100%;
        width: 100%;
        position: relative;
        .videos-wrapper {
          position: absolute;
          top: 46%;
          left: 53%;
          transform: translate(-50%, -50%);
          background: none;
          width: 125px;
          &:nth-child(1) {
            z-index: 4;
          }
          &:nth-child(2) {
            z-index: 3;
            transform: translate(-50%, -53%);
          }
          &:nth-child(3) {
            z-index: 2;
            transform: translate(-50%, -56%);
          }
          &:nth-child(4) {
            z-index: 1;
            transform: translate(-50%, -59%);
          }

          .video-single {
            height: 200px;
            border-radius: 15px;
          }
          // Double Videos Rotation
          .video-rot-double-1 {
            border-radius: 15px;
            height: 200px;
            transform: rotate(-4deg);
          }
          .video-rot-double-2 {
            border-radius: 15px;
            height: 180px;
            transform: rotate(3deg);
          }
          // Three Videos Rotation
          .video-rot-third-1 {
            border-radius: 15px;
            height: 200px;
            transform: rotate(-7deg);
          }
          .video-rot-third-2 {
            border-radius: 10px;
            height: 180px;
          }
          .video-rot-third-3 {
            border-radius: 8px;
            height: 160px;
            transform: rotate(7deg);
          }
          // Four Videos Roation

          .video-rot-four-1 {
            border-radius: 15px;
            height: 200px;
            transform: rotate(-8deg);
          }
          .video-rot-four-2 {
            border-radius: 10px;
            height: 180px;
            transform: rotate(-4deg);
          }
          .video-rot-four-3 {
            height: 160px;
            border-radius: 8px;
          }
          .video-rot-four-4 {
            height: 140px;
            border-radius: 7px;
            transform: rotate(+4deg);
          }
          video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
            transform-origin: bottom left;
          }
        }
      }

      .product-card-dummy-vid {
        font-weight: 700;
        font-size: 9px;
        opacity: 0.5;
        line-height: 12px;
        position: absolute;
        top: 10px;
        left: 10px;
        text-transform: uppercase;
      }
      .product-card-dummy-content {
        position: absolute;
        bottom: 10px;
        left: 10px;
        h1 {
          font-weight: 800;
          font-size: 18px;
          line-height: 21px;
          opacity: 0.5;
        }
        h2 {
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
    .webpage-input-container {
      display: flex;
      flex-direction: column;
      .webpage-input-container-error {
        margin-top: 5px;
        margin-left: 10px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 600;
        color: #e00000;
      }
    }
    .webpage-input-wrapper {
      margin-top: 20px;
      width: 100%;
      .webpage-input {
        margin-top: 20px;
        label {
          font-size: 10px;
          line-height: 12px;
          font-weight: 700;
        }
        .input-wrapper {
          margin-top: 5px;
          display: flex;
          align-items: center;
          background: white;
          padding: 12px;
          border-radius: 6px;
          input {
            background: none;
            border: none;
            box-shadow: none;
            padding: 0px;
            height: 20px;
          }
          .webpage-input-clear {
            height: 20px;
            max-height: 20px;
            width: 20px;
            max-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
        .input-wrapper-border-active {
          border: none;
        }
        .input-wrapper-border-error {
          border: 1px solid #ff0000;
        }
      }
    }

    .bottom-wrapper {
      height: 100px;
      background-color: rgba(255, 255, 255, 0.32);
      .warning-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: rgba(255, 158, 12, 1);
        text-align: center;
        img {
          height: 17px;
          width: 17px;
        }
        .warning-text {
          margin-top: 5px;
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: #fff;
          width: 60%;
        }
        .warning-btn {
          margin-top: 10px;
          position: relative;
          background-color: transparent;
          display: flex;
          font-size: 10px;
          color: #fff;
          font-weight: 600;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            margin: 0;
            padding: 0;
            transform: scale(0.7);
          }
        }
      }
      button {
        bottom: 30px;
      }
    }
    .optional-field {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .title {
        margin: 0;
        margin-left: 10px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
      }
    }
    .optional-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: rgba($color: #000000, $alpha: 0.2);
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(0.9);
      }
      img {
        width: 8px;
      }
    }
  }

  .popup-heading {
    font-size: 17px;
    font-weight: 700;
  }
  textarea {
    width: 100%;
    max-width: 237px;
    min-width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px !important;
    font-weight: 900;
    border: none;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  .info-text {
    font-size: 8px;
    font-weight: 700;
    line-height: 11.52px;
    color: #172fff;
  }
  .info-error {
    font-size: 8px;
    font-weight: 700;
    line-height: 11.52px;
    color: #ff0000;
  }

  .floating-btn {
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: none;
    background-color: transparent !important;
    cursor: pointer;
    &:visited {
      outline: none !important;
      border: 0px !important;
    }
    &:hover,
    &:active,
    &:focus {
      /*Edit at will*/
      outline: none !important;
      border: 0px !important;
    }

    p {
      color: white;
      z-index: 100;
      font-size: 12px;
    }
  }
}

.videos-list {
  width: 112%;
  height: 300px;
  overflow: scroll;
  ul {
    overflow: scroll;
    margin-top: 20px;
    li {
      position: relative;
      font-size: unset;
      line-height: unset;
      font-weight: unset;
      width: 100%;
      display: flex;
      justify-content: unset;
      align-items: center;
      img.equal-drag {
        cursor: pointer;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
      }
    }
    span {
      font-size: 8px;
      font-weight: 700;
      line-height: 11.52px;
      color: #ff0000;
    }
    .video-item {
      font-size: 12px;
      font-weight: 400;
      margin: 30px 0px;
      border-bottom: 1px solid white;
    }
    .video-name-input {
      display: block;
      background-color: transparent;
      border-bottom: 2px solid rgba($color: #000000, $alpha: 0.2);
      border-radius: 0;
      box-shadow: none;
      width: 200px;
      max-width: 500px;
      overflow: visible;
    }
    button {
      position: absolute;
      right: 20px;
      margin-top: 2px;
    }
  }
}

.videos-list .input-main {
  width: 85%;
  margin-left: 18px;
  margin-right: 50px;
}

.video-uploading-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 30px;
    font-weight: 700;
    color: rgba(58, 58, 58, 1);
  }
  p {
    text-align: center;
    font-size: 11px;
    line-height: 13.41px;
    font-weight: 700;
    width: 80%;
    color: rgba(123, 123, 123, 1);
  }
}
.cta-icons {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-btn-cta {
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  width: 100%;
  outline: none !important;
  border: 0px;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.videos-list-uploading {
  width: 100%;
  height: 300px;
  overflow: scroll;
  ul {
    margin-top: 20px;
  }
}

.list-upload-container {
  width: 100%;
  height: 55px;
  background: rgba(255, 255, 255, 0.53);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &-item {
    display: flex;
    flex-direction: column;
    width: 168px;
    margin-left: 20px;
    &-desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        font-weight: bold;
        font-size: 12px;
        line-height: 110%;
        color: #000;
        margin: 0;
      }
      span {
        font-weight: bold;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
      }
    }
    &-loader {
      height: 4px;
      background-color: #d3d3d3;
      border-radius: 14px;
      width: 100%;
      margin-top: 5px;
      position: relative;
      &-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        background-color: #000;
        border-radius: 14px;
      }
    }
  }
  button {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.upload-loading-spin > .loader-animation > svg {
  width: 20px;
  height: 20px;
}
.hover-ani {
  height: 125px;
  width: 125px;
  margin-top: -125px;
}

@-webkit-keyframes hover-ripple-out {
  100% {
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    filter: alpha(opacity=0);
    opacity: 0;
  }
}

@keyframes hover-ripple-out {
  100% {
    top: -20px;
    right: -20px;
    bottom: -20px;
    left: -20px;
    filter: alpha(opacity=0);
    opacity: 0;
  }
}

.hover-ripple-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.hover-ripple-out:before {
  content: '';
  position: absolute;
  border: #cbcbcb solid 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1.6s;
  -webkit-animation-iteration-count: infinite;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  border-radius: 50%;
}

.hover-ripple-out:hover:before,
.hover-ripple-out:focus:before,
.hover-ripple-out:active:before {
  -webkit-animation-name: hover-ripple-out;
  animation-name: hover-ripple-out;
}

.hashtags-wrapper {
  & .hashtags-list {
    max-height: 185px;
    padding-right: 8px;
    &.minimize {
      max-height: 120px;
    }
    &::-webkit-scrollbar {
      width: 2px !important;
      display: block !important;
    }
    &::-webkit-scrollbar-thumb {
      width: 2px !important;
      border-radius: 10px;
      background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
      background: #dcdcdc !important;
      width: 2px !important;
    }
  }
}

.add-v-scroll {
  overflow-y: scroll;
}
