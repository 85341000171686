.stats-graph-wrapper{
  position: absolute;
  top: 0 ;
  left: 0 ;
  width: 100%;
  height: 100%;

  & .legend {
    width: 32px;
    height: 14px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 17px;
    right: -35px;
  }
}