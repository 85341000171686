.upload-wrapper {
  @include media('>tablet', '<=mddesktop') {
    margin-left: 0px;
    width: 40%;
  }
  @include media('>mddesktop', '<=bgdesktop') {
    margin-left: 0px;
    width: 40%;
  }
  .upload-section {
    @include media('>tablet', '<=mddesktop') {
      height: 500px;
      width: 250px;
    }
  }
  .floating-btn {
    @include media('>tablet', '<=mddesktop') {
      transform: translateY(300px);
    }
  }
  .form-wrapper {
    @include media('>tablet', '<=mddesktop') {
      width: 60%;
    }
  }
}
