.one-time-main-container {
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.one-time-container {
    height: 50px;
    background: linear-gradient(103.7deg, rgba(5, 0, 255, 0.81) -22.33%, rgba(0, 0, 0, 0.81) 108.66%);
    box-shadow: 0px 20px 30px rgba(2, 0, 122, 0.15);
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px 0 18px;

    img:hover {
        cursor: pointer;
    }

    h4{
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        white-space: nowrap;
        margin: 0 80px 0 18px;
    }

    &-stores{
        display: flex;
    }

    &-link {
        position: absolute;
        width: 232px;
        height: 34px;
        right: 10px;
        display: flex;
        div{
            width: 50%;
            height: 100%;
        }
    }
}

.one-time-container-link > div:hover {
    cursor: pointer;
}

@media only screen and (min-width: 1180px) and (max-width: 1420px) {
    .one-time-container h4 {
        margin: 0 20px 0 10px;
        white-space: unset;
    }
}

@media only screen and (max-width: 1180px) {
    .one-time-container h4 {
        margin: 0 20px 0 10px;
        white-space: unset;
        min-width: 220px;
    }

    .one-time-main-container {
        left: 45%;
    }
}