.image-wrapper-editmember {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-wrapper {
    overflow: hidden;
    height: 142px;
    width: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0px 6.63884px 49.7913px rgba(0, 0, 0, 0.25);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  h1 {
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    min-height: 19px;
  }
  .type-of-user {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    opacity: 1;
    margin: 6px;
    padding: 0;
  }
  .basic-user {
    color: black;
    opacity: 0.5;
  }
}
