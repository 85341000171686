.plan-wrapper {
  width: 900px;
  max-width: 900px;
  height: 698px;
  max-height: 698px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(70px);
  border-radius: 8px;
  position: relative;
  &.usage-estimate-wrapper {
    width: 900px;
    height: 796px;
    max-height: 796px;
  }
  &__header {
    padding: 24px;
    display: flex;
    align-items: center;
    & .back-btn {
      width: 32px;
      height: 32px;
      background: #ffffff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    & .title {
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      font-weight: 700;
      margin-left: 24px;
    }
  }

  &__content {
    padding-left: 92px;
    padding-top: 36px;
    & h2 {
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #000000;
      font-weight: 700;
    }
    & .progress-bar__wrapper {
      width: 716px;
      height: 24px;
      background: #f1f1f1;
      border: 2px solid #ffffff;
      box-shadow: inset 0px 3.375px 4.5px rgba(0, 0, 0, 0.16);
      border-radius: 100px;
      margin-top: 50px;
      position: relative;
      &.hide-overflow {
        overflow: hidden;
      }
    }
    & .current-progress-bar {
      width: 0%;
      height: 100%;
      background: linear-gradient(90deg, #4b4b4b 1.41%, #000000 100.1%);
      border-radius: 100px 0px 0px 100px;
      animation-name: reducetime;
      animation-duration: 1s;
      position: relative;
      &.br-100 {
        border-radius: 100px;
      }
      & .progress-end {
        position: absolute;
        right: 0px;
        height: 100%;
        bottom: 10px;
        border-right: 1px solid #4425f5;
        & .numbers {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #4425f5;
          position: absolute;
          top: -28px;
          width: 60px;
          text-align: center;
          left: 50%;
          transform: translateX(-50%);
        }
        & .img-wrap {
          position: absolute;
          top: -10px;
          left: -4px;
        }
      }
    }

    & .progress-bar__endpts {
      width: 716px;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      position: relative;
      & .pt {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
    }

    & .next-tier-info {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    & .tier-limit-reached {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #e00000;
      text-align: center;
      width: 520px;
      & span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    & .tier-container {
      height: 322px;
      width: 716px;
      margin-top: 60px;
      background: rgba(255, 255, 255, 0.24);
      backdrop-filter: blur(20px);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 48px;
      display: flex;
      // justify-content: space-between;
      position: relative;
      & .tier-details {
        width: 310px;
        & .tier-name {
          h2 {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #000000;
            margin: 0;
          }
          h1 {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #4425f5;
            margin: 0;
            margin-top: 10px;
            // text-transform: uppercase;
            & img {
              width: 12px;
              height: 10px;
              object-fit: cover;
              margin-right: 10px;
            }
          }
        }

        & .tier-pricing {
          margin-top: 45px;
          & h2 {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #000000;
            margin: 0;
          }
          & .price {
            margin-top: 10px;
            font-size: 64px;
            line-height: 100%;
            color: #000000;
            display: flex;
            column-gap: 6px;
            height: 64px;
            align-items: flex-end;
            font-weight: 700;
            text-transform: capitalize;
            & .currency-type {
              font-size: 36px;
              line-height: 100%;
              display: block;
              margin-bottom: 5px;
            }
          }

          & .tier-end-details {
            margin-top: 12px;
            width: 190px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      & .tier-status {
        // align-self: center;
        &.payment-details {
          margin-left: 70px;
          & h1 {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #000000;
            margin: 0;
          }
          & .payment-card {
            margin-top: 10px;
            display: flex;
            column-gap: 12px;
            & img {
              display: block;
              width: 38px;
              height: 26px;
              background: #ffffff;
              // border: 1px solid #D9D9D9;
              box-sizing: border-box;
              border-radius: 4px;
              align-content: center;
            }
            & .payment-card__brand {
              padding: 0 4px;
              height: 26px;
              background: #ffffff;
              box-sizing: border-box;
              border-radius: 4px;
              border: 1px solid #d9d9d9;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: 700;
            }
            & .payment-card__num {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #000000;
              position: relative;
              & span {
                position: absolute;
                top: 50%;
                left: 50px;
                transform: translateY(-50%);
                margin-left: 4px;
              }
            }
          }

          & .btn-wrapper {
            width: 238px;
            height: 32px;
            box-sizing: border-box;
            border: 1px solid rgba(0, 0, 0, 0.56);
            border-radius: 6px;
            margin-top: 40px;
            & .btn {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
              line-height: 12px;
              font-weight: 700;
              color: rgba(0, 0, 0, 0.56);
              text-transform: uppercase;
              cursor: pointer;
              border-radius: 6px;
              transition: all 0.2s ease-in-out;
              &:hover {
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
              }
              &:active {
                transform: scale(0.98);
              }
            }
          }
        }
        & .subscribe-btn__wrapper {
          margin-top: 90px;
          box-sizing: border-box;
          width: 236px;
          height: 48px;
          background: #000000;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
          border-radius: 6px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          & .subscribe-btn {
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            border-radius: 6px;
            font-weight: 700;
            transition: all 0.2s ease-in-out;
            &:hover {
              box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
            }
            &:active {
              transform: scale(0.98);
            }
          }
        }
      }

      & .tier-sep {
        position: absolute;
        left: 50%;
        top: 30px;
        transform: translateX(-50%);
      }
    }
  }

  & .tier-usage-detail-wrapper {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hide-body-scroll {
  overflow: hidden !important;
}

@keyframes reducetime {
  0% {
    width: 0;
  }
}

.show-loader {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}
