.videos-list-edit {
  color: white;
  width: 100%;
  height: 300px;
  overflow: scroll;
}
ul {
  overflow: scroll;
  margin: 0;
}
button {
  margin: 0px 3px;
}

.video-item-edit {
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 10px;
  cursor: pointer;
  .image-wrapper {
    border-radius: 100%;
    background-color: #fff;
    height: 48px;
    width: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  button {
    text-align: center;
    border: 2px solid white;
    font-size: 10px;
    border-radius: 3px;
    width: 100px;
    color: #fff;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-white {
    background-color: white;
    color: #f6655a;
    width: 50px;
  }
  .video-name {
    font-size: 12px;
    font-weight: 700;
    color: #000;
    background: transparent;
    border: 0px !important;
    box-shadow: none !important;
    width: 80% !important;
    height: 100% !important;
    &::-webkit-input-placeholder {
      font-weight: bold;
      color: black;
    }
    &::-moz-placeholder {
      font-weight: bold;
      color: black;
    }
    &:-ms-input-placeholder {
      font-weight: bold;
      color: black;
    }
    &:-o-input-placeholder {
      font-weight: bold;
      color: black;
    }
  }
  .del-btn {
    overflow: hidden;
    border: 0px;
    width: auto;
    img {
      height: 15px;
    }
  }
}

.video-item-edit-white {
  background-color: rgba($color: #fff, $alpha: 0.5) !important;
}
.video-item-edit-red {
  background-color: rgba($color: #f6655a, $alpha: 0.8);
}
