.card-shadow {
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.2);
}
.propCard {
  width: 300px;
  height: 500px;
  background-size: cover;
}
.fullAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  min-width: 120px;
}
.backDrop {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  height: 98%;
}
.onTheMarket {
  background-color: rgba(0, 0, 0, 0.45);
}
.underOffer {
  background-color: rgba(0, 71, 255, 0.45);
}
.letAgreed {
  background-color: rgba(0, 191, 77, 0.45);
}
.movedIn {
  background-color: rgba(255, 255, 255, 0.7);
}

// New Styles

.prop-card {
  width: 100%;
  height: 110px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  .heading-wrapper {
    padding-right: 50px;
    width: 60%;
    display: flex;
    align-items: center;
    margin-left: -5px;
    a > img {
      margin-top: 5px;
    }
    img {
      height: 84px;
      width: 60px;
      max-width: 60px;
      min-width: 60px;
      background-color: #000000;
      border-radius: 6px;
      overflow: hidden;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
      box-sizing: border-box;
    }
    .heading-title {
      font-size: 8px;
      font-weight: 700;
      margin-left: 30px;
      opacity: 0.2;
      position: absolute;
      top: 10px;
    }
    .heading {
      font-size: 16px;
      font-weight: 700;
      margin-left: 30px;
      word-break: break-word;
    }
  }

  .paired-wrapper {
    min-width: 80px;
    .paired-title {
      font-size: 8px;
      font-weight: 700;
      opacity: 0.2;
      position: absolute;
      top: 10px;
    }
    .copy-btn {
      font-size: 8px;
      line-height: 8px;
      font-weight: 700;
      padding: 8px 12px;
      transition: all 0.2s ease-in-out;
      border-radius: 5px;
      margin-top: 9px;
    }
    .paired {
      background-color: rgba(0, 0, 0, 0.7);
      color: rgba(255, 255, 255, 1);
      box-shadow: 0px 1.28205px 6.41026px rgba(0, 0, 0, 0.25);
      &:hover {
        background-color: rgba(0, 0, 0, 0.9);
      }
    }
    .not-paired {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.3);
      &:hover {
        color: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(0, 0, 0, 0.6);
      }
    }
  }

  .hashtags-wrapper {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hashtags-title {
      font-size: 8px;
      font-weight: 700;
      opacity: 0.2;
      position: absolute;
      top: 10%;
    }
    .hash {
      font-weight: 700;
      font-size: 12px;
      margin: 5px;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }
    .more-hashbtn {
      display: flex;
      margin: 10px;
      background-color: #dddddd;
      padding: 5px;
      border-radius: 20px;
      width: 20px;
      text-align: center;
      font-size: 10px;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .added-videos-wrapper {
    min-width: 185px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .video-title {
      font-size: 8px;
      font-weight: 700;
      opacity: 0.2;
      position: absolute;
      top: 10px;
    }
    .video {
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      object-fit: cover;
      transition: transform 0.2s ease-in-out;
      outline: none;
      object-fit: cover;
      box-sizing: border-box;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.9);
      }
    }
    .video-placeholder {
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: #dddddd;
      transition: transform 0.2s ease-in-out;
      outline: none;
      box-sizing: border-box;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.9);
      }
    }
    .video-placeholder-add {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(115, 115, 115, 0.32);
      transition: transform 0.2s ease-in-out;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.9);
      }
    }
    .more-videobtn {
      margin: 10px;
      background-color: #dddddd;
      padding: 5px;
      border-radius: 20px;
      width: 20px;
      text-align: center;
      font-size: 10px;
      color: white;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .cta-wrapper {
    margin-left: -30px;
    .cta-title {
      font-size: 8px;
      font-weight: 700;
      opacity: 0.2;
      position: absolute;
      top: 10px;
    }
    .cta-btn {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 1;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.9);
      }
    }
    .cta-btn-plus {
      background-color: transparent;
      color: black;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
      position: relative;
      z-index: 2;
      img {
        width: 10px;
      }
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .online-btn-wrapper {
    margin-top: 9px;
    .published-title {
      font-size: 8px;
      font-weight: 700;
      opacity: 0.2;
      position: absolute;
      top: 10px;
    }
  }

  .setting-btn {
    margin-top: 3px;
    img {
      width: 14px;
    }
    .menu {
      position: absolute;
      background-color: white;
      width: 214px;
      height: 104px;
      border-radius: 12px;
      z-index: 100;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      // transform: translate(-85%, -60%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      right: 3px;
      top: 3px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 11px;
        font-weight: 700;
        margin-left: 20px;
        img {
          width: 19px;
          height: 19px;
        }
      }
      .close-btn {
        position: absolute;
        right: 2px;
        top: 2px;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

.grid-wrapper-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4px;
}
.grid-wrapper-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3px;
}

.pro-card-micro {
  min-width: 365px;
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

.default-a-mp {
  margin: 0;
  padding: 0;
  height: 30px;
  width: 30px;
}

// 1920
@media screen and (min-width: 1900px) {
  .heading-wrapper {
    width: 65%;
  }
  .pro-card-micro {
    width: 35%;
  }
}

// 1400
@media screen and (min-width: 1400px) {
  .heading-wrapper {
    width: 50%;
  }
  .pro-card-micro {
    width: 50%;
  }
}

// 1281px
@media screen and (min-width: 1281px) {
  .heading-wrapper {
    width: 45%;
  }
  .pro-card-micro {
    width: 55%;
  }
}
// 1025px
@media screen and (min-width: 1025px) {
  .heading-wrapper {
    width: 40%;
  }
  .pro-card-micro {
    width: 60%;
  }
}
// 801px
@media screen and (min-width: 801px) {
  .heading-wrapper {
    width: 35%;
  }
  .pro-card-micro {
    width: 65%;
  }
}

.video-placeholder-ani {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-placeholder-ani > .loader-animation > svg {
  width: 20px;
  height: 20px;
}
