.back-button {
  height: 34px;
  width: 34px;
  cursor: pointer;
  z-index: 100;
  border-radius: 100%;

  img {
    height: 34px;
    width: 34px;
    cursor: pointer;
  }
}
