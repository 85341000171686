@import url('https://fonts.googleapis.com/css?family=Roboto');
body {
  background: #f3f3f3;
  margin: 0;
  padding: 0;
}

body,
input,
button {
  font-family: 'Montserrat' !important;
}

button {
  border: none;
  background: transparent;
}
ul {
  list-style: none;
}

input {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  min-width: auto;
  padding: 16px 12px;
  border: none;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 900;
  color: black;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  // &:disabled {
  //   cursor: pointer;
  //   background: rgba(0, 0, 0, 0.1) !important;
  // }
}
::-webkit-input-placeholder {
  /* Edge */
  color: #c9c9c9;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c9c9c9;
}

::placeholder {
  color: #c9c9c9;
}

a {
  text-decoration: none;
}

.dummyElement {
  width: 80px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar {
  display: none;
}

.body-no-scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.body-no-scroll::-webkit-scrollbar {
  display: none;
}

// Form
.form-logo {
  margin: 50px 0px;
  transform: scale(0.7);
}

.custom-blur {
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
}

.form-card-wrapper {
  width: 230px;
  padding: 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(225, 225, 225, 0.9);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
  position: relative;

  @include media('<phone') {
    width: 230px;
  }

  @include media('>=phone', '<lgphone') {
    width: 230px;
  }
  @include media('>=lgphone', '<tablet') {
    width: 230px;
  }

  .form-wrapper {
    width: 100%;
    height: 100%;
    h1 {
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
    }
    .input-wrapper {
      width: 100%;
    }
  }
  .back-form {
    display: flex;
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.03);
    }
    &:active {
      transform: scale(0.98);
    }
  }
  .back-button {
    img {
      transition: all 0.2s ease-in-out;
      outline: none;
      background: transparent;
      border: 0px;
      &:hover {
        transform: scale(1.03);
        background: transparent;
      }
      &:active {
        transform: scale(0.98);
        background: transparent;
      }
    }
  }
}

.desktop-view {
  @include media('>lgphone') {
    display: block;
  }
  @include media('<=lgphone') {
    display: none;
  }
}

.mobile-view {
  @include media('>lgphone') {
    display: none;
  }
  @include media('<=lgphone') {
    display: block;
  }
}
// .input-val-error {
//   min-height: 23px;
// }

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  border-radius: 5px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 5px !important;
}

button[disabled] {
  cursor: not-allowed;
}

.sort-line-video-upload {
  border-color: #0000ff !important;
}
