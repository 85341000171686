.team-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}
.active-teams {
  min-height: 660px;
  position: relative;
}

.loading-teams {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
