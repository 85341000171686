.statusmsg-atom {
  margin: 10px 20px;
  padding: 10px 20px;
  background-color: #f6655a;
  border-radius: 6px;
  color: white;
  position: absolute;
  z-index: 10;
  top: 10px;
  width: 40vw;
  font-size: 18px;
  font-weight: 700;
  animation: fadeIn 0.2s linear 0s;

  .message {
    font-size: 18px;
    span {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .status-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f6655a;
    background-color: white;
    height: 50px;
    width: 150px;
    text-align: center;
    border-radius: 6px;
    margin: 10px;
    font-size: 12px;
    outline: none;
    border: 0px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}
.statusmsg-mobile {
  display: none;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6655a;
  .statusmsg-modal {
    margin: 20px;
    padding: 0px 20px;
    border-radius: 24px;

    color: white;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    height: 30vh;

    span {
      font-size: 15px;
      font-weight: 500;
    }
  }
  .btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    button {
      color: white;
      padding: 15px;
      border-radius: 6px;
      font-size: 12px;
      background-color: transparent;
      border: 2px solid white;
      width: 40%;
    }
    .status-link {
      color: #f6655a;
      background-color: white;
      padding: 15px;
      border-radius: 6px;
      font-size: 12px;
      width: 40%;
      text-align: center;
      border: 2px solid white;
    }
  }
}

// Animation

@keyframes fadeIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
